<template>
  <div class="page-container">
    <Headbar>
      <template v-slot:left>
        <h1>{{ $t('reports.reports') }}</h1>
      </template>
    </Headbar>
    <main>
      <FormInputDateTime v-model="from_time" :disabled="isDownloading"
                         :has-error="$v.from_time.$error" :label="$t('reports.from')"
                         :minute-interval="5" :placeholder="$t('reports.from')"
                         class="date-time-input"
                         outputFormat="YYYY-MM-DD HH:mm">
        <template v-slot:errors>
          <p v-if="!$v.from_time.required">
            {{ $t('validation.x_is_required', {x: $t('reports.from')}) }}
          </p>
          <p v-else-if="!$v.from_time.maxValue">
            {{ $t('reports.from_date_before') }}
          </p>
        </template>
      </FormInputDateTime>

      <FormInputDateTime v-model="to_time" :disabled="isDownloading"
                         :has-error="$v.to_time.$error" :label="$t('reports.to')"
                         :minute-interval="5" :placeholder="$t('reports.to')"
                         class="date-time-input"
                         outputFormat="YYYY-MM-DD HH:mm">
        <template v-slot:errors>
          <p v-if="!$v.to_time.required">
            {{ $t('validation.x_is_required', {x: $t('reports.to')}) }}
          </p>
          <p v-else-if="!$v.from_time.minValue">
            {{ $t('reports.to_date_after') }}
          </p>
        </template>
      </FormInputDateTime>


      <FormInputSelect v-model="$v.tasks.$model" :disabled="isDownloading || is_loading_tasks"
                       :display-custom-label="(row) => `${row.attributes.name}`" :has-error="$v.tasks.$error"
                       :label="$t('villas.jobs')" :multiple="true" :options="tasks_options"
                       identifier="tasks"
                       track-by="id">
        <template v-slot:errors>
          <p v-if="!$v.tasks.required">
            {{ $t('validation.x_are_required', {x: $t('villas.jobs')}) }}
          </p>
        </template>
      </FormInputSelect>

      <FormInputSelect v-model="$v.employees.$model" :disabled="isDownloading || is_loading_employees"
                       :display-custom-label="(row) => `${row.attributes.name}`"
                       :has-error="$v.employees.$error"
                       :label="$t('nav.employees')" :multiple="true" :options="employees_options"
                       identifier="tasks"
                       track-by="id">
        <template v-slot:errors>
          <p v-if="!$v.employees.required">
            {{ $t('validation.x_are_required', {x: $t('nav.employees')}) }}
          </p>
        </template>
      </FormInputSelect>

<!--      <FormInputSelect v-model="$v.reports.months.$model" :display-custom-label="(row) => `${row.attributes.name}`"-->
<!--                       :has-error="$v.reports.months.$error"-->
<!--                       :label="$t('reports.month')" :options="months_options"-->
<!--                       :placeholder="$t('reports.month')" identifier="months"-->
<!--                       track-by="id">-->
<!--        <template v-slot:errors>-->
<!--          <p v-if="!$v.reports.months.required">-->
<!--            {{ $t('validation.x_are_required', {x: $t('villas.jobs')}) }}-->
<!--          </p>-->
<!--        </template>-->
<!--      </FormInputSelect>-->
      <Button :onclick="downloadCSV"
              className="--secondary --small">
        {{ $t('reports.exportRep') }}
      </Button>
    </main>
  </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import FormInputSelect from "@/components/form/FormInputSelect.vue";
import {required} from "vuelidate/lib/validators";
import FormInputDateTime from "@/components/form/FormInputDateTime.vue";

export default {
  name: "reports-index-page",
  components: {FormInputDateTime, FormInputSelect, ConfirmModal, Select, Button, Search, Headbar},
  data: function () {
    return {
      reports: [],
      months: null,
      months_options: [],
      is_loading_reports: false,
      is_loading_months: false,
      totalRecords: null,
      headbarExpanded: false,
      isDownloading: false,
      from_time: null,
      to_time: null,
      tasks: [],
      employees: [],
      is_loading_tasks: false,
      is_loading_employees: false,
      employees_options: [],
      tasks_options: [],
    }
  },
  validations: {
    // reports: {
    //   months: {required},
    // },
    from_time: {
      required,
      maxValue(val) {
        return new Date(this.to_time) > new Date(val);
      }
    },
    to_time: {
      required,
      minValue(val) {
        return new Date(val) > new Date(this.from_time);
      },
    },
    tasks: {},
    employees: {},
  },
  methods: {
    async downloadCSV() {
      this.$v.$touch();
      if (this.$v.$anyError || this.isDownloading) return
      this.isDownloading = true
      let currentTimezone = this.$moment.tz.guess()
      let callParams = {
        from: this.from_time,
        to: this.to_time,
      }

      if (currentTimezone) callParams['timezone'] = currentTimezone
      if (this.tasks.length > 0) callParams['task_ids'] = this.tasks.map(x => x.id)
      if (this.employees.length > 0) callParams['employee_ids'] = this.employees.map(x => x.id)

      this.$axios.get('/reports/time-logs', {
        params: {...callParams}})
          .then(({data}) => {
            const file = data.data
            const blob = new Blob([file], {type: "text/csv;charset=utf-8;"})
            const url = URL.createObjectURL(blob)
            const pom = document.createElement("a")
            pom.href = url
            pom.setAttribute("download", this.filename())
            pom.click()
            this.isDownloading = false

          })
          .catch(e => {
            this.isDownloading = false

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('reports.error_export')),
              category: 'error',
            });
          });
    },
    filename() {
      const date = new Date().toLocaleString()
      return `Report ${date}.csv`
    },
    getTasks() {
      this.is_loading_tasks = true;

      this.$axios.get('tasks?per_page=100')
          .then(({data}) => {
            this.tasks_options = data.data;
            this.is_loading_tasks = false;
          })
          .catch(e => {
            this.is_loading_tasks = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('jobs.error_retrieve')),
              category: 'error',
            });
          });
    },
    getEmployees() {
      this.is_loading_employees = true;

      this.$axios.get('employees/list')
          .then(({data}) => {
            this.employees_options = data.data;
            this.is_loading_employees = false;
          })
          .catch(e => {
            this.is_loading_employees = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('employees.error_retrieve')),
              category: 'error',
            });
          });
    },
    /*getReports() {
        this.is_loading_reports = true;

        const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

        this.$axios.get('reports', {params: {...this.serverParams, sorting: encodedSorting}})
            .then(({data}) => {
                this.reports = data.data;
                this.totalRecords = data.meta.total;
                this.is_loading_reports = false;
            })
            .catch(e => {
                this.is_loading_reports = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data, this.$t('reports.error_retrieve')),
                    type: 'error',
                });
            });
    }*/
  },
  getMonths() {
    this.is_loading_reports = true;

    const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

    this.$axios.get('months', {params: {...this.serverParams, sorting: encodedSorting}})
        .then(({data}) => {
          this.reports = data.data;
          this.totalRecords = data.meta.total;
          this.is_loading_months = false;
        })
        .catch(e => {
          this.is_loading_months = false;

          this.$notify({
            title: this.$t('error'),
            text: this.$larerror(e.response.data, this.$t('months.error_retrieve')),
            type: 'error',
          });
        });
  },
  mounted() {
    this.getTasks();
    this.getEmployees();
  },
  head() {
    return {
      title: {
        inner: this.$t('nav.reports')
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .headbar-expanded-container {
    @apply mt-4 flex flex-col gap-y-4 items-end mr-10;

    @screen md {
      @apply hidden;
    }
  }

  .select-dropdown {
    @apply min-w-40;
  }

  .btn-search-mobile, .btn-filter-mobile {
    @apply block text-secondary w-6 mr-0;

    @screen md {
      @apply hidden;
    }

    &:active, &:focus {
      @apply outline-none;
    }
  }

  .search-desktop, .filter-desktop {
    @apply hidden;

    @screen md {
      @apply block;
    }
  }

  .search-container {
    @apply mr-0;
  }

  .filter-mobile {
    @apply max-w-xs;
  }

  .td-after {
    @apply flex flex-row;

    & > * {
      @apply mr-3;

      &:last-child {
        @apply mr-0;
      }
    }
  }
}
</style>
