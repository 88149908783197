<template>
    <div class="employees-update-modal">
        <ModalContainer :title="$t('employees.edit_employee')" identifier="employees-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.employee.name.$model" identifier="name" :label="$t('employees.name')"
                               :placeholder="$t('employees.name')" :disabled="isLoading || is_saving"
                               :has-error="$v.employee.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.employee.name.required">
                            {{$t('validation.x_is_required',{x: $t('employees.name')})}}
                        </p>
                    </template>
                </FormInputText>
                <Button type="submit" className="--secondary --small" :class="{spinner: is_saving}"
                        :disabled="isLoading || is_saving || original && original.attributes.name === employee.name">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "EmployeesUpdateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        props: {
            employee_id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                original: null,
                employee: {
                    name: null,
                },
                is_saving: false,
                is_loading_original: false,
            }
        },
        validations: {
            employee: {
                name: {required},
            }
        },
        computed: {
            isLoading() {
                return this.is_loading_original || this.is_loading_roles;
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('employees-update-modal', status);
            },
            save() {
                this.$v.employee.$touch();
                if (this.$v.employee.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                let payload = {}

                if (this.original.attributes.name !== this.$v.employee.name.$model)
                    payload.name = this.$v.employee.name.$model;

                this.$axios.patch(`employees/${this.employee_id}`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('employees.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('employees.error_update')),
                        type: 'error',
                    });
                });
            },
            async getOriginalEmployee() {
                this.is_loading_original = true;
                
                await this.$axios.get(`employees/${this.employee_id}`)
                    .then(({data}) => {
                        this.is_loading_original = false;
                        this.original = data.data;

                        this.$v.employee.name.$model = this.original.attributes.name;
                    })
                    .catch(e => {
                        this.is_loading_original = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('employees.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        async mounted() {
            await this.getOriginalEmployee();
        }
    }
</script>
