<template>
    <div class="villas-update-modal">
        <ModalContainer :title="$t('villas.edit_villa')" identifier="villas-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.villa.name.$model" identifier="name" :label="$t('villas.name')"
                               :placeholder="$t('villas.name')" :disabled="isLoading || is_saving"
                               :has-error="$v.villa.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.villa.name.required">
                            {{$t('validation.x_is_required',{x: $t('villas.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.villa.address.$model" identifier="address" :label="$t('villas.address')"
                               :placeholder="$t('villas.address')" :disabled="isLoading || is_saving"
                               :has-error="$v.villa.address.$error" autocomplete="off">
                    <template v-slot:errors>
                        <p v-if="!$v.villa.address.required">
                            {{$t('validation.x_is_required',{x: $t('villas.address')})}}
                        </p>
                        <p v-else-if="!$v.villa.address.required">
                            {{$t('auth.enter_valid_address')}}
                        </p>
                    </template>
                </FormInputText>

              <FormInputSelect v-model="$v.villa.tasks.$model" identifier="tasks" :label="$t('villas.jobs')" :options="tasks_options"
                               :disabled="is_saving || is_loading_tasks || is_loading_villa_tasks" :has-error="$v.villa.tasks.$error" track-by="id" :multiple="true"
                               :display-custom-label="(row) => `${row.attributes.name}`">
                <template v-slot:errors>
                  <p v-if="!$v.villa.tasks.required">
                    {{$t('validation.x_are_required',{x: $t('villas.jobs')})}}
                  </p>
                </template>
              </FormInputSelect>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}" :disabled="isLoading || is_saving">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "VillasUpdateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        props: {
            villa_id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                original: null,
                villa: {
                    name: null,
                    address: null,
                    tasks: null,
                },
                is_saving: false,
                is_loading_original: false,
              is_loading_villa_tasks: false,
              is_loading_tasks: false,
              tasks_options: [],
            }
        },
        validations: {
            villa: {
                name: {required},
                address: {required},
                tasks: {},
            }
        },
        computed: {
            isLoading() {
                return this.is_loading_original;
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('villas-update-modal', status);
            },
            save() {
                this.$v.villa.$touch();
                if (this.$v.villa.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                let payload = {}

                if (this.original.attributes.name !== this.$v.villa.name.$model)
                    payload.name = this.$v.villa.name.$model;

                if (this.original.attributes.address !== this.$v.villa.address.$model)
                    payload.address = this.$v.villa.address.$model;

                if (this.$v.villa.tasks.$model)
                    payload.tasks = this.$v.villa.tasks.$model;

                this.$axios.patch(`villas/${this.villa_id}`, payload).then(({data}) => {
                  if (payload?.tasks?.length > 0) {
                    let tempTasks = payload.tasks.map(x => x.id)
                    let taskPayload = {
                      task_ids: tempTasks
                    }
                    this.$axios.post(`villas/${this.villa_id}/tasks`, taskPayload)
                  }
                    this.$notify({
                        text: this.$t('villas.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('villas.error_update')),
                        type: 'error',
                    });
                });
            },
            async populate() {
                if (!this.original || !this.villa)
                    return;

                this.$v.villa.name.$model = this.original.attributes.name;
                this.$v.villa.address.$model = this.original.attributes.address;
                this.$v.villa.tasks.$model = this.original.attributes.tasks;
            },
            async getOriginalVilla() {
                this.is_loading_original = true;
                
                await this.$axios.get(`villas/${this.villa_id}`)
                    .then(({data}) => {
                        this.is_loading_original = false;
                        this.original = data.data;
                    })
                    .catch(e => {
                        this.is_loading_original = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('villas.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
          async getTasks() {
            this.is_loading_tasks = true;

            await this.$axios.get('tasks?per_page=50')
                .then(({data}) => {
                  this.tasks_options = data.data;
                  this.is_loading_tasks = false;
                })
                .catch(e => {
                  this.is_loading_tasks = false;

                  this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data, this.$t('jobs.error_retrieve')),
                    category: 'error',
                  });
                });
          },
          async getVillaTasks() {
            this.is_loading_villa_tasks = true;

            await this.$axios.get(`villas/${this.villa_id}/tasks`)
              .then(({data}) => {
                this.original.attributes.tasks = data.data;
                this.is_loading_villa_tasks = false;
              })
              .catch(e => {
                this.is_loading_villa_tasks = false;

                this.$notify({
                  title: this.$t('error'),
                  text: this.$larerror(e.response.data, this.$t('jobs.error_retrieve')),
                  category: 'error',
                });
              });
          }
        },
        async mounted() {
            await this.getOriginalVilla();
          await this.getVillaTasks();
          await this.getTasks();
          await this.populate();
        }
    }
</script>
