<template>
    <div class="users-create-modal">
        <ModalContainer :title="$t('users.add_user')" identifier="users-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.user.name.$model" identifier="name" :label="$t('users.name')"
                               :placeholder="$t('users.name')" :disabled="is_saving"
                               :has-error="$v.user.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.user.name.required">
                            {{$t('validation.x_is_required',{x: $t('users.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.user.email.$model" identifier="email" :label="$t('users.email')"
                               :placeholder="$t('users.email')" :disabled="is_saving"
                               :has-error="$v.user.email.$error" autocomplete="off">
                    <template v-slot:errors>
                        <p v-if="!$v.user.email.required">
                            {{$t('validation.x_is_required',{x: $t('users.email')})}}
                        </p>
                        <p v-else-if="!$v.user.email.email">
                            {{$t('auth.enter_valid_email')}}
                        </p>
                    </template>
                </FormInputText>

                <FormGroupTwo>
                    <FormInputPassword v-model="$v.user.password.$model" identifier="password" :label="$t('users.password')"
                                       :placeholder="$t('users.password')" :disabled="is_saving" type="password"
                                       :has-error="$v.user.password.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.user.password.required">
                                {{$t('validation.x_is_required',{x: $t('users.password')})}}
                            </p>
                        </template>
                    </FormInputPassword>
                    <FormInputSelect v-model="$v.user.role.$model" identifier="role"
                                     :label="$t('users.role')" :options="roleOptions"
                                     :placeholder="$t('users.role')" :disabled="is_saving"
                                     :has-error="$v.user.role.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.user.role.required">
                                {{$t('validation.x_is_required',{x: $t('users.role')})}}
                            </p>
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>
                <Button type="submit" className="--secondary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "UsersCreateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                user: {
                    name: null,
                    email: null,
                    password: null,
                    role: null,
                },
                is_saving: false,
                is_loading_roles: false,
                roleOptions: []
            }
        },
        validations: {
            user: {
                name: {required},
                email: {required, email},
                password: {required},
                role: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('users-create-modal', status);
            },
            save() {
                this.$v.user.$touch();
                if (this.$v.user.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.user.$model);
                payload.roles = [this.$v.user.role.$model.id];

                this.$axios.post(`users`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('users.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('users.error_create')),
                        type: 'error',
                    });
                });
            },
            getRoleOptions() {
                this.is_loading_roles = false;
                this.$axios.get('roles/list')
                    .then(({data}) => {
                        this.roleOptions = data.data;
                        this.is_loading_roles = false;
                    })
                    .catch(e => {
                        this.is_loading_roles = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                            type: 'error',
                        });
                    });
            }
        },
        mounted() {
            this.getRoleOptions();
        }
    }
</script>
