export default {
    nav: {
        home: 'Home',
        login: 'Login',
        reset_password: 'Reset Password',
        employees: 'Employees',
        reports: 'Reports',
        villas: 'Villas',
        jobs: 'Jobs',
        roles_permissions: 'Roles & Permissions',
        users: 'Users',
        profile: 'Profile',
        logout: 'Logout',
        signup: 'Sign Up',
    },
    profile: {
        profile: 'profile',
        user_account: 'User Account',
        personal_information: 'Personal Information',
        address_information: 'Address Information',
        change_password: 'Change Password',
        success_updated: 'Your Account has been updated',
        error_update: 'Failed to update profile'
    },
    employees: {
        employees: 'employees',
        select_all: 'Select All',
        deselect_all: 'Deselect All',
        add_employee: 'Add Employee',
        edit_employee: 'Edit Employee',
        search_employees: 'Search Employees',
        name: 'Name',
        clocked_in: 'Clocked In',
        clocked_out: 'Clocked Out',
        clock_in: 'Clock In',
        clock_out: 'Clock Out',
        time: 'Time',
        date_time: 'Date & Time',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this employee?',
        success_created: 'Employee has been created',
        success_updated: 'Employee has been updated',
        success_deleted: 'Employee has been deleted',
        success_clocked_in: 'Employees have been clocked in',
        success_clocked_out: 'Employees have been clocked out',
        error_create: 'Failed to create employee',
        error_update: 'Failed to update employee',
        error_delete: 'Failed to delete employee',
        error_retrieve: 'Failed to retrieve employees',
        error_clock_in: 'Failed to clock in employees',
        error_clock_out: 'Failed to clock out employees',
        employee_details: 'Employee Details',
        update_employee: 'Update Employee',
        delete_employee: 'Delete Employee',
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search Users',
        name: 'Name',
        company: 'Company',
        mobile_number: 'Mobile Number',
        phone_mobile_number: 'Phone / Mobile Number',
        address: 'Address',
        email: 'Email',
        phone: 'Phone',
        email_address: 'Email Address',
        vat_number: 'VAT Number',
        city: 'City',
        post_code: 'Post Code',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this user?',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        success_deleted: 'User has been deleted',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        user_details: 'User Details',
        update_user: 'Update User',
        delete_user: 'Delete User',
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search Roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'Role has been created',
        success_updated: 'Role has been updated',
        success_deleted: 'Role has been deleted',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    villas: {
        villas: 'villas',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        address: 'Address',
        jobs: 'Jobs',
        add_villa: 'Add Villa',
        edit_villa: 'Edit Villa',
        search_villas: 'Search Villas',
        prompt_delete: 'Are you sure you want to delete this villa?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve villas',
        error_create: 'Failed to create villa',
        error_update: 'Failed to update villa',
        error_delete: 'Failed to delete villa',
        success_created: 'Villa has been created',
        success_updated: 'Villa has been updated',
        success_deleted: 'Villa has been deleted',
        error_retrieve_fields: 'Failed to retrieve fields',
        villa_details: 'Villa Details',
        delete_villa: 'Delete Villa',
    },
    jobs: {
        job: 'Job',
        jobs: 'Jobs',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        address: 'Address',
        add_job: 'Add Job',
        edit_job: 'Edit Job',
        search_jobs: 'Search Jobs',
        prompt_delete: 'Are you sure you want to delete this job?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve jobs',
        error_create: 'Failed to create job',
        error_update: 'Failed to update job',
        error_delete: 'Failed to delete job',
        success_created: 'Job has been created',
        success_updated: 'Job has been updated',
        success_deleted: 'Job has been deleted',
        error_retrieve_fields: 'Failed to retrieve fields',
        job_details: 'Job Details',
        delete_job: 'Delete Job',
    },
    reports: {
        to: 'To',
        from: 'From',
        reports: 'Reports',
        exportRep: 'Export Report (CSV)',
        export: 'Export',
        month: 'Choose Month',
        error_export: 'Failed to export report',
        to_date_after: 'To date must be after From date',
        from_date_before: 'From date must be before To date',
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
        passwords_must_match: 'Passwords must match',
        password_must_be_atleast_x_characters: 'Password must be at least {x} characters',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        setup_your_account: 'Setup your account.',
        account_created: 'Account successfully created',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password!',
        failed_signup: 'Signup Failed',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    start: 'Start',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    print: 'Print',
    send: 'Send',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    actions: 'Actions',
    back: 'Back',
    submit: 'Submit',
    assign: 'Assign',
    okay: 'Okay',
    signup: 'Sign Up',
}
