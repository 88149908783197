<template>
    <div class="headbar-container">
        <div class="left">
            <slot name="left"></slot>
        </div>
        <div class="right">
            <slot name="right"></slot>
        </div>
        <div class="expanded">
            <slot name="expanded"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Headbar"
}
</script>

<style lang="scss" scoped>
.headbar-container {
    @apply w-full flex flex-row flex-wrap items-center justify-between py-7 px-6 border-b-2 border-secondary;

    @screen md {
        @apply px-9;
    }

    h1 {
        @apply text-secondary font-bold text-3xl capitalize;
    }

    .left, .right {
        @apply flex flex-row items-center flex-none;

        & > * {
            @apply mx-2;

            &:first-child {
                @apply ml-0;
            }

            &:last-child {
                @apply mr-0;
            }
        }
    }

    .expanded {
        @apply w-full;
    }
}
</style>