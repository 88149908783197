<template>
    <div class="employees-clock-out-modal">
        <ModalContainer class="modal-container" :title="$t('employees.clock_out')" identifier="employees-clock-out-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputDateTime class="date-time-input" v-model="date_time"
                                   :label="$t('employees.date_time')" :placeholder="$t('employees.date_time')"
                                   :disabled="is_saving" :minute-interval="5"
                                   :has-error="$v.date_time.$error"
                                   outputFormat="YYYY-MM-DD HH:mm">
                    <template v-slot:errors>
                        <p v-if="!$v.date_time.required">
                            {{ $t('validation.x_is_required', {x: $t('employees.date_time')}) }}
                        </p>
                    </template>
                </FormInputDateTime>

                <div class="buttons-container">
                    <Button className="--secondary --outline --small" :onclick="() => close(false)">{{$t('cancel')}}</Button>
                    <Button type="submit" className="--secondary --small" :disabled="!date_time" :class="{spinner: is_saving}">{{$t('employees.clock_out')}}</Button>
                </div>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputDateTime from "@/components/form/FormInputDateTime.vue";

    export default {
        name: "EmployeesClockOutModal",
        components: {FormInputDateTime, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        props: {
            employee_ids: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                date_time: null,
                is_saving: false,
            }
        },
        validations: {
            date_time: {required}
        },
        methods: {
            close(status) {
                this.$modal.hide('employees-clock-out-modal', status);
            },
            save() {
                this.$v.date_time.$touch();
                if (this.$v.date_time.$anyError || this.is_saving) return;

                this.is_saving = true;

                let payload = {
                    employee_ids: this.employee_ids,
                    clocked_out: this.date_time
                };

                this.$axios.post(`employees/clock-out`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('employees.success_clocked_out'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('employees.error_clock_out')),
                        type: 'error',
                    });
                });
            },
        },
    }
</script>

<style lang="scss">
.employees-clock-out-modal {
    & > .modal-container {
        @apply flex w-full;

        & > main {
            & > form {
                & > .date-time-input {
                    @apply mx-auto max-w-xs;
                }

                & > .buttons-container {
                    @apply grid grid-cols-2 gap-x-4 mx-auto;
                }
            }
        }
    }
}
</style>
