<template>
  <div class="employees-clock-in-modal">
    <ModalContainer :closable="true" :title="$t('employees.clock_in')" class="modal-container"
                    identifier="employees-clock-in-modal">
      <Form :disabled="is_saving" class="form" @submit="save">
        <FormInputDateTime v-model="date_time" :disabled="is_saving"
                           :has-error="$v.date_time.$error" :label="$t('employees.date_time')"
                           :minute-interval="5" :placeholder="$t('employees.date_time')"
                           class="date-time-input"
                           outputFormat="YYYY-MM-DD HH:mm">
          <template v-slot:errors>
            <p v-if="!$v.date_time.required">
              {{ $t('validation.x_is_required', {x: $t('employees.date_time')}) }}
            </p>
          </template>
        </FormInputDateTime>

        <FormInputSelect v-model="$v.task_id.$model" :disabled="is_saving || is_loading_tasks" :display-custom-label="(row) => `${row.attributes.name}`"
                         :has-error="$v.task_id.$error" class="date-time-input"
                         :label="$t('jobs.job')" :options="tasks_options"
                         identifier="tasks" track-by="id">
          <template v-slot:errors>
            <p v-if="!$v.task_id.required">
              {{ $t('validation.x_are_required', {x: $t('jobs.job')}) }}
            </p>
          </template>
        </FormInputSelect>

        <div class="buttons-container">
          <Button :onclick="() => close(false)" className="--secondary --outline --small">{{ $t('cancel') }}</Button>
          <Button :class="{spinner: is_saving}" :disabled="!date_time" className="--secondary --small" type="submit">
            {{ $t('employees.clock_in') }}
          </Button>
        </div>
      </Form>
    </ModalContainer>
  </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputDateTime from "@/components/form/FormInputDateTime.vue";

export default {
  name: "EmployeesClockInModal",
  components: {FormInputDateTime, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
  props: {
    employee_ids: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      date_time: null,
      task_id: null,
      is_saving: false,
      is_loading_tasks: false,
      tasks_options: [],
    }
  },
  validations: {
    date_time: {required},
    task_id: {},
  },
  methods: {
    getTasks() {
      this.is_loading_tasks = true;

      this.$axios.get('tasks?per_page=50')
          .then(({data}) => {
            this.tasks_options = data.data;
            this.is_loading_tasks = false;
          })
          .catch(e => {
            this.is_loading_tasks = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('jobs.error_retrieve')),
              category: 'error',
            });
          });
    },
    close(status) {
      this.$modal.hide('employees-clock-in-modal', status);
    },
    save() {
      this.$v.date_time.$touch();
      if (this.$v.date_time.$anyError || this.is_saving) return;

      this.is_saving = true;

      let payload = {
        employee_ids: this.employee_ids,
        clocked_in: this.date_time,
      };

      if (this.task_id) {
        payload['task_id'] = this.task_id.id
      }

      this.$axios.post(`employees/clock-in`, payload).then(({data}) => {
        this.$notify({
          text: this.$t('employees.success_clocked_in'),
          type: 'success',
        });

        this.is_saving = false;
        this.close(true);
      }).catch(e => {
        this.is_saving = false;

        this.$notify({
          title: this.$t('error'),
          text: this.$larerror(e.response.data.errors, this.$t('employees.error_clock_in')),
          type: 'error',
        });
      });
    },
  },
  mounted() {
    this.getTasks();
  }
}
</script>

<style lang="scss">
.employees-clock-in-modal {
  & > .modal-container {
    @apply flex w-full;

    & > main {
      & > form {
        & > .date-time-input {
          @apply mx-auto max-w-xs;
        }

        & > .buttons-container {
          @apply grid grid-cols-2 gap-x-4 mx-auto;
        }
      }
    }
  }
}
</style>
