<template>
    <div class="employees-create-modal">
        <ModalContainer :title="$t('employees.add_employee')" identifier="employees-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.employee.name.$model" identifier="name" :label="$t('employees.name')"
                               :placeholder="$t('employees.name')" :disabled="is_saving"
                               :has-error="$v.employee.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.employee.name.required">
                            {{$t('validation.x_is_required',{x: $t('employees.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--secondary --small" :class="{spinner: is_saving}"
                :disabled="!employee.name">{{$t('save')}}</Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required} from 'vuelidate/lib/validators'
    import Button from "../Button";

    export default {
        name: "EmployeesCreateModal",
        components: {Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                employee: {
                    name: null,
                },
                is_saving: false,
            }
        },
        validations: {
            employee: {
                name: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('employees-create-modal', status);
            },
            save() {
                this.$v.employee.$touch();
                if (this.$v.employee.$anyError || this.is_saving) return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.employee.$model);

                this.$axios.post(`employees`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('employees.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('employees.error_create')),
                        type: 'error',
                    });
                });
            },
        },
    }
</script>
