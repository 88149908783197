<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1>{{$t('employees.employees')}}</h1>
            </template>
            <template v-slot:right>
                <Button className="--secondary --small"
                        :onclick="selectAllEmployees">
                    {{ $t('employees.select_all') }}
                </Button>

                <Button className="--secondary --small"
                        :onclick="deselectAllEmployees">
                    {{ $t('employees.deselect_all') }}
                </Button>

                <Button className="--secondary --small"
                        :onclick="toggleClockIn" :disabled="!selectedEmployees.length">
                    {{ $t('employees.clock_in') }}
                </Button>

                <Button className="--secondary --small"
                        :onclick="toggleClockOut" :disabled="!selectedEmployees.length">
                    {{ $t('employees.clock_out') }}
                </Button>

                <Button v-if="$store.getters.hasPermission('store employees')" className="--secondary --small"
                        :onclick="toggleCreate">
                    {{ $t('employees.add_employee') }}
                </Button>

                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>

                <Search class="search-desktop" :placeholder="$t('employees.search_employees')" @search="search"/>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('employees.search_employees')" @search="search"/>
                </div>
            </template>
        </Headbar>

        <main>
            <loading-screen v-if="is_loading_employees"/>
            <div v-else class="employee-cards-wrapper">
                <div class="employee-card" v-for="employee in employees" :key="employee.id">
                    <div class="checkbox-container">
                        <input type="checkbox" class="checkbox" v-model="employee.check"/>
                    </div>

                    <div class="details-container">
                        <p>{{employee.attributes.name}}</p>
                      <div v-if="employee.attributes.status === 'clocked_in'" class="px-4 py-2 rounded-lg bg-positive bg-opacity-5 clocked-in">
                        <p class="text-positive font-semibold text-center">{{$t('employees.clocked_in')}}</p>
                      </div>
                      <div v-else-if="employee.attributes.status === 'clocked_out'" class="px-4 py-2 rounded-lg bg-negative bg-opacity-5 clocked-out">
                        <p class="text-negative font-semibold text-center">{{$t('employees.clocked_out')}}</p>
                      </div>
                      <p class="pt-1" v-if="employee.attributes.status === 'clocked_in' && returnEmployeeJob(employee)"><span class="text-sm text-grey-darker">Current Job: </span><span>{{returnEmployeeJob(employee)}}</span></p>
                    </div>

                    <div class="buttons-container">
                        <Button v-if="$store.getters.hasPermission('update employees')"
                                class="button" className="--secondary --outline --mini"
                                :onclick="()=>toggleUpdate(employee.id)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button v-if="$store.getters.hasPermission('destroy employees')"
                                class="button" className="--secondary --outline --mini"
                                :onclick="()=>toggleDelete(employee.id)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import EmployeesCreateModal from "../../components/employees/EmployeesCreateModal";
import EmployeesUpdateModal from "../../components/employees/EmployeesUpdateModal";
import LoadingScreen from "@/components/LoadingScreen.vue";
import EmployeesClockInModal from "@/components/employees/EmployeesClockInModal.vue";
import EmployeesClockOutModal from "@/components/employees/EmployeesClockOutModal.vue";

export default {
    name: "employees-index-page",
    components: {LoadingScreen, ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        return {
            employees: [],
            is_loading_employees: false,
            totalRecords: null,
            serverParams: {},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    computed: {
        selectedEmployees() {
            return this.employees.filter(employee => employee.check);
        }
    },
    methods: {
        toggleDelete(employee) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('employees.delete_employee'),
                    message: this.$t('employees.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--secondary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`employees/${employee}`)
                                .then(({data}) => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        text: this.$t('employees.success_deleted'),
                                        type: 'success',
                                    });

                                    this.getEmployees(false);
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('employees.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        selectAllEmployees() {
            this.employees.forEach(employee => {
                this.$set(employee, 'check', true);
            })
        },
        deselectAllEmployees() {
            this.employees.forEach(employee => {
                this.$set(employee, 'check', false);
            })
        },
        returnEmployeeJob(employee) {
          if (!employee?.relationships?.time_logs?.data?.[employee.relationships.time_logs.data.length - 1]?.relationships?.jobs?.data?.attributes?.name) return null
          return employee.relationships.time_logs.data[employee.relationships.time_logs.data.length - 1].relationships.jobs.data?.attributes?.name
        },
        toggleCreate() {
            this.$modal.show(
                EmployeesCreateModal, {},
                {
                    name: 'employees-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getEmployees();
                    }
                }
            );
        },
        toggleUpdate(employee) {
            this.$modal.show(
                EmployeesUpdateModal,
                {
                    employee_id: employee,
                },
                {
                    name: 'employees-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getEmployees(false);
                    }
                }
            );
        },
        toggleClockIn() {
            let employee_ids = this.employees.filter(employee => employee.check).map(employee => employee.id);

            this.$modal.show(
                EmployeesClockInModal, {
                    employee_ids: employee_ids
                },
                {
                    name: 'employees-clock-in-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getEmployees(false);
                    }
                }
            );
        },
        toggleClockOut() {
            let employee_ids = this.employees.filter(employee => employee.check).map(employee => employee.id);

            this.$modal.show(
                EmployeesClockOutModal, {
                    employee_ids: employee_ids
                },
                {
                    name: 'employees-clock-out-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getEmployees(false);
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getEmployees();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.getEmployees();
        },
        getEmployees(loader = true) {
            if(loader) this.is_loading_employees = true;

            this.$axios.get('employees', {params: this.serverParams})
                .then(({data}) => {
                    this.employees = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_employees = false;
                })
                .catch(e => {
                    this.is_loading_employees = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('employees.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getEmployees();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.employees')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-container {
        h1 {
            @apply mb-4;

            @screen xl {
                @apply mb-0;
            }
        }
    }

    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-end mr-10;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-secondary w-6 mr-0;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    main {
        & > .employee-cards-wrapper {
            @apply grid grid-cols-1 gap-x-4 gap-y-4;

            @screen md {
                @apply grid-cols-2;
            }

            @screen lg {
                @apply grid-cols-3;
            }

            @screen 2xl {
                @apply grid-cols-5;
            }

            & > .employee-card {
                @apply rounded-lg border border-secondary flex flex-row;

                & > .checkbox-container {
                    @apply flex p-4 border-r border-secondary;

                    & > .checkbox {
                        @apply my-auto w-5 h-5 rounded-lg;
                        accent-color: theme('colors.primary');
                    }
                }

                & > .details-container {
                    @apply flex-1 flex flex-col p-4 font-bold text-sm border-r border-secondary;

                    & > p {
                        &.clocked-in {
                            @apply text-positive;
                        }

                        &.clocked-out {
                            @apply text-negative;
                        }

                        &:first-of-type {
                            @apply mb-1;
                        }
                    }
                }

                & > .buttons-container {
                    @apply flex flex-row flex-wrap p-4;

                    & > .button {
                        @apply my-auto;

                        &:first-of-type {
                            @apply mr-2;
                        }
                    }
                }
            }
        }
    }
}
</style>
