<template>
  <div class="login-page-container darken">
    <div class="content">
      <img alt="logo" class="logo" src="../../src/assets/logo.png"/>

      <Form v-if="screen === 'login'" :disabled="is_processing" class="form-container login-form" @submit="login">

        <FormInputText v-model="$v.credentials.email.$model" :disabled="is_processing" :has-error="$v.credentials.email.$error"
                       :placeholder="$t('users.email')" autocomplete="on">
          <template v-slot:errors>
            <p v-if="!$v.credentials.email.required">
              {{ $t('auth.email_is_required') }}
            </p>
            <p v-else-if="!$v.credentials.email.email">
              {{ $t('auth.enter_valid_email') }}
            </p>
          </template>
        </FormInputText>

        <FormInputPassword v-model="$v.credentials.password.$model" :disabled="is_processing"
                           :has-error="$v.credentials.password.$error" :placeholder="$t('users.password')" autocomplete="on"
                           identifier="password" type="password">
          <template v-slot:errors>
            <p v-if="!$v.credentials.password.required">
              {{ $t('validation.x_is_required', {x: $t('users.password')}) }}
            </p>
            <p v-else-if="!$v.credentials.password.minLength">
              {{ $t('validation.password_must_be_atleast_x_characters', {x: 8}) }}
            </p>
          </template>
        </FormInputPassword>

        <div class="submit-row">
          <Button :class="{'spinner-black': is_processing}" :disabled="is_processing" className="--secondary"
                  type="submit">
            <span>{{ $t('nav.login') }}</span>
          </Button>

          <a class="forgot" @click="screen = 'forgot'">
            {{ $t('auth.forgot_password_q') }}
          </a>
        </div>
      </Form>
      <div v-else-if="screen === 'forgot'" class="forgot-wrapper">
        <button class="back" @click="screen = 'login'">
          <font-awesome-icon :icon="['fal','long-arrow-left']"/>
        </button>
        <Form :disabled="is_processing" class="form-container forgot-form" @submit="forgot">

          <p>{{ $t('auth.forgot_password_text') }}</p>

          <FormInputText v-model="$v.credentials.email.$model" :has-error="$v.credentials.email.$error"
                         autocomplete="off">
            <template v-slot:errors>
              <p v-if="!$v.credentials.email.required">
                {{ $t('auth.email_is_required') }}
              </p>
              <p v-else-if="!$v.credentials.email.email">
                {{ $t('auth.enter_valid_email') }}
              </p>
            </template>
          </FormInputText>

          <div class="submit-row">
            <Button :class="{'spinner-black': is_processing}" :disabled="is_processing"
                    className="--primary mt-4" type="submit">
              {{ $t('auth.reset_password') }}
            </Button>
          </div>
        </Form>
      </div>
      <Form v-else-if="screen === 'reset'" class="reset-form">
        <p>{{ $t('auth.email_sent') }}</p>

        <font-awesome-icon :icon="['fal','check-circle']"/>
        <div class="submit-row">
          <Button :onclick="() => this.screen = 'login'" className="--secondary">
            {{ $t('nav.login') }}
          </Button>
        </div>
      </Form>
    </div>
    <div class="engineered-by-arkafort">
      <p>Engineered by</p>
      <img alt="Arkafort" src="../assets/white-logo.svg"/>
    </div>
  </div>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'
import Button from "../components/Button";
import Form from "../components/form/Form";
import FormInputText from "@/components/form/FormInputText";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
  name: "login-page",
  components: {FormInputPassword, FormInputText, Form, Button},
  data() {
    return {
      credentials: {
        email: '',
        password: '',
      },
      show_password: false,
      is_processing: false,
      screen: 'login',
    }
  },
  validations: {
    credentials: {
      email: {
        required,
        email,
      },
      password: {
        required,
      }
    }
  },
  methods: {
    login() {
      this.$v.credentials.$touch();
      if (this.$v.credentials.$anyError || this.is_processing)
        return;

      this.is_processing = true;
      this.$axios.post('auth/login', {
        email: this.$v.credentials.email.$model,
        password: this.$v.credentials.password.$model,
      }).then(async ({data}) => {
        this.$store.commit("SET_TOKEN", data.data.token);
        await this.$store.dispatch('getUser');

        this.is_processing = false;
        if (this.$store.getters.hasRole('organisation')) {
          this.$router.push('bookings');
        } else if (this.$store.getters.hasRole('user')) {
          this.$router.push('employees');
        } else {
          this.$router.push('/');
        }
      }).catch(e => {
        this.is_processing = false;

        this.$notify({
          title: this.$t('error'),
          text: this.$larerror(e.response.data.errors, this.$t('auth.failed_login')),
          type: 'error',
        });
      });
    },
    forgot() {
      this.$v.credentials.email.$touch();
      if (this.$v.credentials.email.$anyError || this.is_processing)
        return;

      this.is_processing = true;
      this.$axios.post('auth/password/reset/request', {
        email: this.$v.credentials.email.$model,
      }).then(async ({data}) => {
        this.screen = 'reset';

        this.is_processing = false;
      }).catch(e => {
        this.is_processing = false;

        this.$notify({
          title: this.$t('error'),
          text: this.$larerror(e.response.data.errors, this.$t('auth.failed_reset')),
          type: 'error',
        });
      });
    }
  },
  head() {
    return {
      title: {
        inner: this.$t('nav.login')
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page-container {
  @apply bg-cover bg-center bg-no-repeat bg-primary min-h-screen w-full flex flex-col justify-center items-center;

  //&.darken {
  //    position: relative;
  //
  //    &:after {
  //        content: '';
  //        position: absolute;
  //        top: 0;
  //        bottom: 0;
  //        left: 0;
  //        right: 0;
  //        display: block;
  //        background-color: rgba(0, 0, 0, 0.38);
  //    }
  //}

  .content {
    @apply px-10 py-8 rounded-lg flex flex-col max-w-full bg-white;
    width: 90vw;
    //background-color: rgba(0, 0, 0, 0.65);
    z-index: 1;

    @screen sm {
      @apply max-w-md;
    }

    .logo {
      @apply w-auto mb-8 object-contain h-12;
    }

    .form-container {
      @apply bg-white;

      .submit-row {
        @apply flex flex-row justify-between items-center;
        margin-top: 0rem !important;

        a.forgot {
          @apply text-black text-sm no-underline cursor-pointer font-bold;
        }
      }
    }

    .login-form {
      button[type="submit"] {
        @apply self-start;
      }

      .submit-row {
        @apply flex flex-row flex-wrap mt-3;

        button[type=button] {
          @apply ml-2;
        }
      }
    }

    .forgot-wrapper {
      button.back {
        @apply absolute top-0 left-0;

        svg {
          @apply text-white text-3xl mt-11 ml-10;
        }

        &:active, &:focus {
          @apply outline-none;
        }
      }

      .forgot-form {
        @apply flex flex-col items-center;

        & > p {
          @apply text-black font-normal leading-6 text-base text-center mb-8;
          max-width: 16rem;
        }
      }
    }

    .reset-form {
      @apply flex flex-col items-center mb-7;

      & > p {
        @apply text-black font-normal text-base text-center max-w-92 mt-3;
      }

      svg {
        @apply text-secondary my-8;
        font-size: 5.65rem;
      }

      .submit-row {
        @apply flex flex-row justify-between items-center w-full;

        button {
          @apply mx-auto cursor-pointer;
        }
      }
    }
  }

  .engineered-by-arkafort {
    @apply flex flex-row absolute items-center;
    top: 92.5%;

    & > p {
      @apply text-sm text-white;
    }

    & > img {
      @apply ml-4 h-4;
    }
  }
}
</style>
