<template>
    <div class="villas-create-modal">
        <ModalContainer :title="$t('villas.add_villa')" identifier="villas-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.villa.name.$model" identifier="name" :label="$t('villas.name')"
                               :placeholder="$t('villas.name')" :disabled="is_saving"
                               :has-error="$v.villa.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.villa.name.required">
                            {{$t('validation.x_is_required',{x: $t('villas.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.villa.address.$model" identifier="address" :label="$t('villas.address')"
                               :placeholder="$t('villas.address')" :disabled="is_saving"
                               :has-error="$v.villa.address.$error" autocomplete="off">
                    <template v-slot:errors>
                        <p v-if="!$v.villa.address.required">
                            {{$t('validation.x_is_required',{x: $t('villas.address')})}}
                        </p>
                        <p v-else-if="!$v.villa.address.required">
                            {{$t('auth.enter_valid_address')}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputSelect v-model="$v.villa.tasks.$model" identifier="tasks" :label="$t('villas.jobs')" :options="tasks_options"
                                 :disabled="is_saving || is_loading_tasks" :has-error="$v.villa.tasks.$error" track-by="id" :multiple="true"
                                 :display-custom-label="(row) => `${row.attributes.name}`">
                    <template v-slot:errors>
                        <p v-if="!$v.villa.tasks.required">
                            {{$t('validation.x_are_required',{x: $t('villas.jobs')})}}
                        </p>
                    </template>
                </FormInputSelect>
                <Button type="submit" className="--secondary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "VillasCreateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                villa: {
                    name: null,
                    address: null,
                    tasks: null,
                },
                is_saving: false,
                is_loading_tasks: false,
                tasks_options: [],
            }
        },
        validations: {
            villa: {
                name: {required},
                address: {required},
                tasks: {},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('villas-create-modal', status);
            },
            getTasks() {
                this.is_loading_tasks = true;

                this.$axios.get('tasks?per_page=50')
                    .then(({data}) => {
                        this.tasks_options = data.data;
                        this.is_loading_tasks = false;
                    })
                    .catch(e => {
                        this.is_loading_tasks = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('jobs.error_retrieve')),
                            category: 'error',
                        });
                    });
            },
            save() {
                this.$v.villa.$touch();
                if (this.$v.villa.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.villa.$model);

                this.$axios.post(`villas`, payload).then(({data}) => {
                  if (payload?.tasks?.length > 0) {
                    let tempTasks = payload.tasks.map(x => x.id)
                    let taskPayload = {
                        task_ids: tempTasks
                    }
                    this.$axios.post(`villas/${data.data.id}/tasks`, taskPayload)
                  }
                  this.$notify({
                    text: this.$t('villas.success_created'),
                    type: 'success',
                  });
                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('villas.error_create')),
                        type: 'error',
                    });
                });
            },
        },
        mounted() {
            this.getTasks();
        }
    }
</script>
